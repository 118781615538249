<template>
  <v-container class="p-4">
    <section class="d-flex flex-column flex-sm-row justify-content-between">
      <h2 class="ml-1 my-3">
        Abastecimiento - Resumen
      </h2>
      <v-breadcrumbs :items="breadcrumb">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item active-class="active" :to="item.to" :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </section>
    <v-data-table
      class="elevation-3 px-3 mt-1"
      :headers="tableHeaders"
      :items="wrappedCubages"
      :items-per-page="20"
      item-key="id"
      hide-default-footer
      no-data-text="Sin puntos de instalación"
      @page-count="pageCount = $event"
    >
      <template v-slot:item.actions="{ item }">
        <div class="d-flex align-items-center justify-content-center">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="
                  $router.push({
                    name: 'supplying-summary-show',
                    params: {
                      codigoLugarInstalacion: item.code
                    }
                  })
                "
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>
                  mdi-eye
                </v-icon>
              </v-btn>
            </template>
            <span>Ver</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  mounted() {
    this.getAllCubagesClosed()
  },
  data() {
    return {
      filters: {
        page: 1
      },
      pageCount: 0,
      breadcrumb: [
        {
          text: 'Proyectos',
          to: { name: 'proyectos' }
        },
        {
          text: 'Abastecimiento - Resumen',
          disabled: true
        }
      ],
      tableHeaders: [
        {
          text: 'Código del punto de instalación',
          align: 'center',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Acciones',
          align: 'center',
          value: 'actions'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      cubagesClosed: 'cubage/cubagesClosed'
    }),
    wrappedCubages() {
      return this.cubagesClosed
        .map((item) => {
          return {
            ...item,
            name: `${item.place_id}`,
            code: item.place_id
          }
        })
        .filter((item) => item.name !== 'undefined')
    }
  },
  methods: {
    ...mapActions({
      getAllCubagesClosed: 'cubage/getAllCubagesClosed'
    })
  }
}
</script>

<style></style>
