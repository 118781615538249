var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"p-4"},[_c('section',{staticClass:"d-flex flex-column flex-sm-row justify-content-between"},[_c('h2',{staticClass:"ml-1 my-3"},[_vm._v(" Abastecimiento - Resumen ")]),_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumb},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-breadcrumbs-item',{attrs:{"active-class":"active","to":item.to,"disabled":item.disabled}},[_vm._v(" "+_vm._s(item.text)+" ")])]}}])})],1),_c('v-data-table',{staticClass:"elevation-3 px-3 mt-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.wrappedCubages,"items-per-page":20,"item-key":"id","hide-default-footer":"","no-data-text":"Sin puntos de instalación"},on:{"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.$router.push({
                  name: 'supplying-summary-show',
                  params: {
                    codigoLugarInstalacion: item.code
                  }
                })}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver")])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }